import { cn } from '~/utils/misc.ts';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../ui/accordion.tsx';

const faq = [
	{
		title: 'What is hostU?',
		description:
			'hostU facilitates medium-term (1-8 month) shared housing rentals within University communities. Our matching algorithm connects those in need of housing with those looking to sublet their space, based on preferences and needs. ',
	},
	{
		title: 'Who is hostU for?',
		description:
			'hostU is curated exclusively for university undergraduates, graduates, and faculty with a valid .edu email address. Whether you are studying abroad or interning in a new city, hostU facilitates your housing process. ',
	},
	{
		title: 'How does it work?',
		description:
			'To join hostU, complete the short survey to tell us about your housing needs. Our algorithm will verify university-affiliation and connect you to guests or hosts with matching criteria. Guests will receive matching listings and have the option to message hosts on-platform. Hosts will only hear from verified and interested guests. Once matched, users can communicate, make arrangements, and transact confidently and easily.',
	},
	{
		title: 'How much does hostU cost?',
		description:
			'It is free to submit a listing and receive matches on hostU. Only on completed transactions will the host be charged a 10% service fee. We will always strive to keep our fees reasonable and transparent.',
	},
];

export function FAQSection({ className }: { className?: string }) {
	return (
		<section
			className={cn('flex w-full items-center justify-center px-6', className)}
		>
			<div className="w-full max-w-[1440px]">
				<div className="pb-2 pt-12 md:pb-20 md:pt-20">
					<h2 className="text-2xl font-bold tracking-tight">
						Frequently asked questions
					</h2>
					<p className="pt-1 text-sm leading-6 text-gray-500">
						For further questions, email us at{' '}
						<a href="mailto:hello@joinhostu.com" className="font-medium">
							hello@joinhostu.com
						</a>
					</p>
					<div className="mt-6 border-t border-primary border-opacity-75 pt-4 md:pt-10">
						<Accordion type="single" collapsible className="block md:hidden">
							<div>
								<FAQItemMobile {...faq[0]} />
								<FAQItemMobile {...faq[1]} />
							</div>
							<div>
								<FAQItemMobile {...faq[2]} />
								<FAQItemMobile {...faq[3]} className="border-none" />
							</div>
						</Accordion>
						<dl className="hidden md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
							{faq.map(item => (
								<FAQItem key={item.title} {...item} />
							))}
						</dl>
					</div>
				</div>
			</div>
		</section>
	);
}

type FAQItemProps = {
	title: string;
	description: string;
	className?: string;
};

export function FAQItem(props: FAQItemProps) {
	const { title, description, className } = props;

	return (
		<div className={className}>
			<dt className="text-lg font-medium leading-6">{title}</dt>
			<dd className="mt-2 text-base text-gray-500">{description}</dd>
		</div>
	);
}

export function FAQItemMobile(props: FAQItemProps) {
	const { title, description, className } = props;

	return (
		<AccordionItem value={title} className={cn('m-0', className)}>
			<AccordionTrigger className="text-lg font-medium hover:no-underline">
				{title}
			</AccordionTrigger>
			<AccordionContent className="text-base text-gray-500">
				{description}
			</AccordionContent>
		</AccordionItem>
	);
}
