import { Link } from '@remix-run/react';
import { Button } from '../ui/button.tsx';
import { cn } from '#app/utils/misc.js';
import { useAppMode } from '../dashboard/app-mode-provider.tsx';

export function WhatAreYouWaitingForSection({
	className,
}: {
	className?: string;
}) {
	const mode = useAppMode();
	return (
		<div
			className={cn(
				'relative flex w-full flex-col items-start justify-center gap-6 overflow-hidden bg-primary pb-20 pl-8 pr-4 pt-10 text-white sm:items-center sm:py-10 sm:pl-4 md:px-8',
				className,
			)}
		>
			<section className="flex w-full max-w-[1440px] flex-col items-start justify-between gap-6 sm:items-center md:flex-row">
				<div className="flex flex-col gap-1 text-start max-sm:max-w-64">
					<h3 className="text-base font-semibold sm:text-5xl md:text-3xl">
						What are you waiting for?
					</h3>
					<p className="text-start text-base text-gray-100 sm:text-center md:text-start md:text-2xl">
						{mode === 'host' ? (
							'Start getting paid today'
						) : (
							<>
								Your personalized sublet is <br className="inline sm:hidden" />{' '}
								waiting for you
							</>
						)}
					</p>
				</div>
				<div>
					<Button
						variant="black"
						className="rounded-full bg-black/50 px-7 text-base font-semibold sm:px-5 sm:py-6 sm:font-normal md:px-7 md:py-7 md:text-xl lg:text-2xl"
						asChild
					>
						<Link to="/app/host">Get started </Link>
					</Button>
				</div>
			</section>
			<img
				src="/img/landing/rounded-listing.png"
				alt="listing example"
				className="absolute -bottom-1 -right-2 block w-48 sm:hidden"
			/>
		</div>
	);
}
